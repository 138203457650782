<body>
    <app-navequipo></app-navequipo>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="well well-sm">
                    <div class="row align-items-center">
                        <div class="col text-white mt-5 columna">
                            <h3 class="text-center">MISIÓN</h3>
                            <p>Somos una Fundación que trabaja para contribuir a la formación de tejido social,
                                promoviendo los
                                derechos de las personas, especialmente de los niños, niñas y adolescentes a través de
                                la
                                educación,
                                el deporte y la cultura, busca servir de apoyo a la sociedad en general haciendo énfasis
                                en
                                aquellos
                                grupos en condiciones de vulnerabilidad o con riesgo de exclusión social, así como
                                adelantar
                                acciones sociales de beneficencia y patrocinio que contribuyan a ayudar a los demás de
                                manera
                                desinteresada.</p>
                        </div>
                        <div class="col text-white mt-5 columna">
                            <h3 class="text-center">VISIÓN</h3>
                            <p>La Fundación Carlos Cuesta "UBALA" se convertirá en una institución que mediante la
                                tecnología, idiomas, arte o deporte y la implementación de cursos cortos en diversas
                                áreas ayudará a mejorar la calidad de la eduación, aportará herramientas para
                                incursionar en la vida laboral; así mismo será referente en programas de beneficiencia,
                                lo cual redundará en un mejoramiento de la calidad de vida de los habitantes de la
                                región.
                            </p>
                        </div>
                    </div>
                    <div class="img">
                        <img src="assets/valores.png" class="img-fluid" alt="Valores">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="badground">
        <div class="col-md-8">
            <figure class="figure bg-dark">
                <img src="/assets/grupofundacion.jpg" class="figure-img img-fluid rounded" alt="...">
                <figcaption class="figure-caption text-end text-white">FUNDACIÓN CARLOS CUESTA "UBALA".</figcaption>
            </figure>
        </div>
    </div>
</body>