import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proyeccion-social',
  templateUrl: './proyeccion-social.component.html',
  styleUrls: ['./proyeccion-social.component.scss']
})
export class ProyeccionSocialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
