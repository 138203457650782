<ul class="nav">
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/semillas">SEMILLAS</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/patrocinios">PATROCINIOS</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/proyeccion-social">PROYECCIÓN SOCIAL</a>
  </li>
</ul>