import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './component/login/login.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { ContactoComponent } from './component/contacto/contacto.component';
import { DonarComponent } from './component/donar/donar.component';
import { EquipoComponent } from './component/equipo/equipo.component';
import { FundacionComponent } from './component/fundacion/fundacion.component';
import { InicioComponent } from './component/inicio/inicio.component';
import { MisionComponent } from './component/mision/mision.component';
import { NavequipoComponent } from './component/navequipo/navequipo.component';
import { NavquehacemosComponent } from './component/navquehacemos/navquehacemos.component';
import { NoticiaComponent } from './component/noticias/noticia/noticia.component';
import { VistaComponent } from './component/noticias/vista/vista.component';
import { PatrocinioComponent } from './component/patrocinio/patrocinio.component';
import { ProyeccionSocialComponent } from './component/proyeccion-social/proyeccion-social.component';
import { QueHacemosComponent } from './component/que-hacemos/que-hacemos.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { NoticiaService } from './services/noticia/noticia.service';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireModule } from '@angular/fire/compat';
import { provideStorage,getStorage } from '@angular/fire/storage';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    QuienesSomosComponent,
    QueHacemosComponent,
    ContactoComponent,
    DonarComponent,
    MisionComponent,
    EquipoComponent,
    NavequipoComponent,
    FundacionComponent,
    NavquehacemosComponent,
    PatrocinioComponent,
    InicioComponent,
    ProyeccionSocialComponent,
    NoticiaComponent,
    VistaComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    AngularFireModule.initializeApp(environment.firebase),
    provideAuth(() => getAuth()),
    AngularFirestoreModule,
    provideStorage(() => getStorage()),
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    NoticiaService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
