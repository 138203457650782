<body class="text-center mt-5">
    <img class="mb-5" src="/assets/fundacion.png" alt="fundacion.png" width="100" height="100">
    <div class="login mb-5">
      <form #f class="custom-form" [formGroup]="form">
        <h1>Login</h1>
        <input
          type="email"
          formControlName="email"
          name="email"
          placeholder="Email"
          required="required"
          autofocus="on"
          autocomplete=""
        />
        <input
          type="password"
          formControlName="password"
          name="password"
          placeholder="Password"
          required="required"
          autocomplete="off"
        />
        <button type="submit" (click)="login()">Log in</button>
      </form>
    </div>
</body>