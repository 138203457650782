// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'serverfcc-db912',
    appId: '1:975587528027:web:c6fd20b7355af43719de7e',
    storageBucket: 'serverfcc-db912.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyB54TLkHoj3pIhJGK9MYMuNpph4YlaVoqo',
    authDomain: 'serverfcc-db912.firebaseapp.com',
    messagingSenderId: '975587528027',
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
