import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { doc, onSnapshot } from 'firebase/firestore';
import { Observable } from 'rxjs';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root',
})
export class NoticiaService {
  noticiasCollection: AngularFirestoreCollection<any>;
  constructor(private db: AngularFirestore, private http: HttpClient) {
    this.noticiasCollection = db.collection('/noticias');
  }

  createNotice(service: any) {
    return this.noticiasCollection.add(service);
  }

  updateNotice(service: any) {
    return this.db.collection('usuarios').doc(service.uid).update({
      title: service.title,
      detail: service.detail,
      urlImage: service.urlImage,
    });
  }

  delete(id: string) {
    return this.db.collection('usuarios').doc(id).delete();
  }

  getAllNotice() {
    return this.noticiasCollection.snapshotChanges().pipe();
  }
}
