import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-donar',
  templateUrl: './donar.component.html',
  styleUrls: ['./donar.component.scss']
})
export class DonarComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  }
}


