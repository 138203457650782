<body>

  <app-navequipo></app-navequipo>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="well well-sm">
          <div class="container-fluid">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css" rel="stylesheet"
              integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6"
              crossorigin="anonymous">
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js"
              integrity="sha384-JEW9xMcG8R+pH31jmWH6WWP0WintQrMb4s7ZOdauHnUtxwoG2vI5DkLtS3qm9Ekf"
              crossorigin="anonymous"></script>
            <div class="row row-cols-1 row-cols-md-4 g-2 offset-md-1">
              <div class="col">
                <div class="avatar">
                  <img src="assets/carlos.jpg" class="card-img-top" alt="Carlos Cuesta" height="200" >
                  <div class="card-body">
                    <h5 class="card-title">FUNDADOR</h5>
                    <p class="card-text">CARLOS CUESTA FIGUEROA</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/keysi.jpg" class="card-img-top" alt="Keysi Maturana" height="200">
                  <div class="card-body">
                    <h5 class="card-title">DOCENTE DE RECREACION Y DEPORTE</h5>
                    <p class="card-text">KEISY MATURANA ANDRADE</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/viejo.jpg" class="card-img-top" alt="Yonifer Maturana" height="200">
                  <div class="card-body">
                    <h5 class="card-title">DOCENTE DE RECREACION Y DEPORTE</h5>
                    <p class="card-text">JHONIFER MATURANA</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/nhela1.jpg" class="card-img-top" alt="Yinela Renteria" height="200">
                  <div class="card-body">
                    <h5 class="card-title">DOCENTE DE INGLES</h5>
                    <p class="card-text">YINELA RENTERIA CALDERON</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/robin.jpg" class="card-img-top" alt="Robinson Mosquera" height="200">
                  <div class="card-body">
                    <h5 class="card-title">COORDINADOR ACADEMICO</h5>
                    <p class="card-text">ROBINSON JAVIER MOSQUERA PEREA</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/yadira1.jpg" class="card-img-top" alt="Yadira Figueroa" height="200">
                  <div class="card-body">
                    <h5 class="card-title">REPRESENTANTE LEGAL</h5>
                    <p class="card-text">YADIRA FIGUEROA MOSQUERA</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/elias.jpg" class="card-img-top" alt="Elias Trujillo" height="200">
                  <div class="card-body">
                    <h5 class="card-title">DOCENTE DE INFORMATICA</h5>
                    <p class="card-text">JOHN ELIAS TRUJILLO FIGUEROA</p>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="avatar">
                  <img src="assets/noreyda.jpg" class="card-img-top" alt="Elias Trujillo" height="200">
                  <div class="card-body">
                    <h5 class="card-title">PSICOORIENTADORA</h5>
                    <p class="card-text">NOREYDA MATURANA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>