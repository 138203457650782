<script src="https://unpkg.com/ionicons@5.0.0/dist/ionicons.js"></script>

<body class="m-0 row justify-content-center">
  <div class="container-fluid">
    <div id="carousel1" class="carousel slide " data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="/assets/charla3.jpg" class="d-block w-100" alt="Dia del niño">
          <div class="carousel-caption ">
            <h5>COMUNICACIÓN ACERTIVA CON NIÑOS, NIÑAS Y ADOLECENTES</h5>
            <p>La comunicacion es uno de los aspectos mas relevantes para poder acercarnos a los niños, niñas y
              adolecentes.</p>
            <button type="button" [routerLink]="['/semillas']" routerLinkActive="router-link-active"
              class="btn btn-primary btn-circle btn-lg">Ver mas</button>
          </div>
        </div>
        <div class="carousel-item">
          <img src="/assets/grupo.jpg" class="d-block w-100 " alt="...">
          <div class="carousel-caption ">
            <h5>CONOCENOS</h5>
            <p>Pequeño grupo de estudiantes.</p>
            <button type="button" [routerLink]="['/quienes-somos']" routerLinkActive="router-link-active"
              class="btn btn-primary btn-circle btn-lg">Ver mas</button>
          </div>
        </div>
        <div class="carousel-item">
          <img src="/assets/entregaregalo3.jpg" class="d-block w-100" alt="...">
          <div class="carousel-caption ">
            <h5>PESEBRE</h5>
            <p>Celebramos con mucho entusiasmo el nacimiento del niño Jesús.</p>
            <button type="button" [routerLink]="['/donar']" routerLinkActive="router-link-active"
              class="btn btn-primary btn-circle btn-lg">Ver mas</button>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carousel1" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carousel1" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden"></span>
      </button>
    </div>
  </div>

  <div class="programa mt-5">
    <div class="container ">
      <div class="row">
        <div class="col-md-12 col-md-offset-3 text-center fh5co-heading">
          <h2>PROGRAMAS</h2>
          <hr>
          <p class="p" style="text-align: justify;">Actualmente adelantamos nuestro trabajo formativo en 4 disciplinas
            que sirven para contextualizar
            a nuestros niños, niñas y jóvenes para afrontar la vida cotidiana.</p>
        </div>
      </div>
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
              aria-expanded="true" aria-controls="collapseOne">
              SEMILLITAS
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row mt-5">
                <div class="col-md-6 text-center animate-box fadeInUp animated-fast ">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor"
                      class="bi bi-dribbble" viewBox="0 0 16 16">
                      <path fill-rule="evenodd"
                        d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
                    </svg>
                    <div class="desc">
                      <h5>Deporte</h5>
                      <p style="text-align: justify;">Potenciar la práctica deportiva del voleibol y el fútbol en la
                        Fundación Carlos Cuesta, encaminadas a la formación y preparación del deportista, orientado
                        hacia la obtención de máximos logros de la práctica deportiva a nivel municipal, departamental y
                        nacional.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6  text-center animate-box fadeInUp animated-fast">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor"
                      class="bi bi-book" viewBox="0 0 16 16">
                      <path
                        d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811V2.828zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492V2.687zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783z" />
                    </svg>
                    <div class="desc">
                      <h5>Inglés</h5>
                      <p style="text-align: justify;">
                        Contribución al aprendizaje de una lengua extranjera, Inglés, con el desarrollo de las 4
                        habilidades
                        comunicativas (escucha, habla, lectura y escritura), poniendo a su alcance elementos culturales
                        y sociales contextualizados a través de los contenidos temáticos y espacios
                        pedagógicos-recreativos, potenciando sus capacidades comunicativas.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 text-center animate-box fadeInUp animated-fast mt-5">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" width="42" height="42">
                      <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                      <path
                        d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z" />
                    </svg>
                    <div class="desc">
                      <h5>Psicosocial</h5>
                      <p style="text-align: justify;">El acompañamiento psicosocial es un proceso orientado a
                        transformar la relaciones al interior de la fundación, las familias y las comunidades,
                        favoreciendo las garantías de los derechos de los niños, niñas y adolescentes.</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6  text-center animate-box fadeInUp animated-fast mt-5">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor"
                      class="bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                      <path
                        d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
                    </svg>
                    <div class="desc">
                      <h5>Tecnología e Informática</h5> 
                      <p style="text-align: justify;">Se enfoca en fomentar la innovación y el cambio de mentalidad a
                        través de una serie de
                        herramientas que le permite a los niños, niñas y adolescentes enfrentar al mundo.</p>
                    </div>
                  </div>
                </div>
                <div>
                  <button type="button" [routerLink]="['/semillas']" routerLinkActive="router-link-active"
                    class="btn btn-primary">Ver más</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              PATROCINIO
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row mt-5 justify-content-evenly">
                <div class="col-md-3 col-sm-8 text-center animate-box fadeInUp animated-fast">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 640 512">
                      <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                      <path
                        d="M640 162.8c0 6.917-2.293 13.88-7.012 19.7l-49.96 61.63c-6.32 7.796-15.62 11.85-25.01 11.85c-7.01 0-14.07-2.262-19.97-6.919L480 203.3V464c0 26.51-21.49 48-48 48H208C181.5 512 160 490.5 160 464V203.3L101.1 249.1C96.05 253.7 88.99 255.1 81.98 255.1c-9.388 0-18.69-4.057-25.01-11.85L7.012 182.5C2.292 176.7-.0003 169.7-.0003 162.8c0-9.262 4.111-18.44 12.01-24.68l135-106.6C159.8 21.49 175.7 16 191.1 16H225.6C233.3 61.36 272.5 96 320 96s86.73-34.64 94.39-80h33.6c16.35 0 32.22 5.49 44.99 15.57l135 106.6C635.9 144.4 640 153.6 640 162.8z" />
                    </svg>
                    <div class="desc">
                      <h5>Uniformes Deportivos</h5>
                      <p style="text-align: justify;"></p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-sm-8 text-center animate-box fadeInUp animated-fast">
                  <div class="services">
                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 512 512">
                      <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                      <path
                        d="M177.1 228.6L207.9 320h96.5l29.62-91.38L256 172.1L177.1 228.6zM255.1 0C114.6 0 .0001 114.6 .0001 256S114.6 512 256 512s255.1-114.6 255.1-255.1S397.4 0 255.1 0zM435.2 361.1l-103.9-1.578l-30.67 99.52C286.2 462.2 271.3 464 256 464s-30.19-1.773-44.56-4.93L180.8 359.6L76.83 361.1c-14.93-25.35-24.79-54.01-27.8-84.72L134.3 216.4L100.7 118.1c19.85-22.34 44.32-40.45 72.04-52.62L256 128l83.29-62.47c27.72 12.17 52.19 30.27 72.04 52.62L377.7 216.4l85.23 59.97C459.1 307.1 450.1 335.8 435.2 361.1z" />
                    </svg>
                    <div class="desc">
                      <h5>Indumentaria Deportiva</h5>
                      <p style="text-align: justify;"></p>
                    </div>
                  </div>
                </div>
                <p style="text-align: justify;">Promueve el desarrollo de las habilidades y destrezas en el área
                  deportiva de los jóvenes en el municipio de Condoto a través del patrocinio de indumentaria deportiva
                  para la realización de torneos locales y a distintas escuelas deportivas para que los niños, niñas y
                  adolescentes puedan ocupar el tiempo libre en la realización de actividades que les sirvan para la
                  vida y la sana convivencia.</p>
                <div>
                  <button type="button" [routerLink]="['/patrocinios']" routerLinkActive="router-link-active"
                    class="btn btn-primary">Ver más</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              PROYECCIÓN SOCIAL
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="col-md-12 col-sm-12 text-center animate-box fadeInUp animated-fast">
                <div class="services">
                  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="currentColor"
                    class="bi bi-people" viewBox="0 0 16 16">
                    <path
                      d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                  </svg>
                  <div class="desc">
                    <h5>Proyección Social</h5>
                    <p style="text-align: justify;">Fortalecimiento de habilidades y capacidades en los niños, niñas y
                      adolescentes, a través de actividades lúdicas, recreativas, formativas y contenido adecuado que
                      generen impacto social en nuestra comunidad y fuera de ella teniendo en cuenta la adecuación de
                      las infraestructuras físicas o escenarios deportivos donde se realizan las actividades propias de
                      la FCC.</p>
                  </div>
                </div>
              </div>
              <div>
                <button type="button" [routerLink]="['/proyeccion-social']" routerLinkActive="router-link-active"
                  class="btn btn-primary">Ver más</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



    <div class="col-md-12 embed-container  text-dark text-center mt-5">
      <h2>TESTIMONIOS</h2>
      <iframe src="https://drive.google.com/file/d/1HbNyXFGqsBd9VtAbsahrxNhUA4ncHd7Z/preview" width="800" height="480" class="embed-responsive embed-responsive-1by1"
        allow="autoplay"></iframe>
    </div>


  <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js"
    integrity="sha384-JEW9xMcG8R+pH31jmWH6WWP0WintQrMb4s7ZOdauHnUtxwoG2vI5DkLtS3qm9Ekf"
    crossorigin="anonymous"></script>



  <h1 class="text-dark" style="text-align: center;">¿DÓNDE ESTAMOS UBICADOS?</h1>
  <div class="container">
    <div class="row mapa">
      <iframe class="embed-responsive embed-responsive-1by1"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15896.34962031653!2d-76.65789928390012!3d5.089567314097783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e48100361370285%3A0x8ca43199f1c90a20!2zQ29uZG90bywgQ2hvY8Oz!5e0!3m2!1ses!2sco!4v1629302667938!5m2!1ses!2sco"
        style="border:0;" allowfullscreen="" loading="lazy" width="300" height="300"></iframe>
    </div>
  </div>
</body>