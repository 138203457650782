<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div
        class="d-grid gap-2 d-md-flex justify-content-md-end mb-3 position-relative mt-3"
      >
        <div class="">
          <button
            *ngIf="token"
            class="btn btn-success btn-sm"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Agregar noticia
          </button>
        </div>
      </div>
      <div class="card mb-5" *ngIf="noticeItem.value.length > 0">
        <div
          class="row g-0 mb-3"
          *ngFor="let noticias of noticeItem.value; let i = index"
        >
          <div class="col-md-6">
            <img
              [src]="noticias.urlImage"
              class="img-fluid rounded-start"
              alt="imagenes"
            />
          </div>
          <div class="col-md-6">
            <div class="card-body">
              <h5 class="card-title">{{ noticias.title }}</h5>
              <p class="card-text">{{ noticias.detail }}</p>
              <p class="card-text">
                <small class="text-muted">Fundación Carlos Cuesta</small>
              </p>
            </div>
            <div
              *ngIf="token"
              class="d-grid gap-2 d-md-flex justify-content-md-end"
            >
              <button class="btn btn-primary me-md-2 btn-sm" type="button">
                Editar
              </button>
              <button class="btn btn-danger me-md-2 btn-sm" type="button">
                Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="noticeItem.value.length == 0"
        class="col-md-12 align-self-center"
      >
        <h2 style="text-align: center">No hay noticias para mostrar</h2>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Agregar noticia</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body contenedor">
        <div class="input-group mb-3">
          <span class="input-group-text" id="basic-addon1">Titulo: </span>
          <input
            type="text"
            class="form-control"
            placeholder="Username"
            [(ngModel)]="noti.titulo"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>
        <div class="input-group mb-3">
          <span class="input-group-text">Descripción: </span>
          <textarea
            class="form-control"
            aria-label="With textarea"
            [(ngModel)]="noti.descripcion"
          ></textarea>
        </div>
        <div class="input-group mb-3">
          <input
            type="file"
            class="form-control"
            id="inputGroupFile02"
            accept="jpg, png, jpeg"
            (change)="file($event)"
            required
          />
          <label class="input-group-text" for="inputGroupFile02">Upload</label>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          (click)="sendFile()"
        >
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
