import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navequipo',
  templateUrl: './navequipo.component.html',
  styleUrls: ['./navequipo.component.scss']
})
export class NavequipoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
