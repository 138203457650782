import { Injectable } from '@angular/core';
import { Auth, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginData } from 'src/app/core/login-data';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  user: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public readonly currentUser: Observable<any> = this.user.asObservable();
  constructor(private auth: Auth) {}

  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password).then(
      async (res) => {
        this.user.next(res.user);
        const token = await this.user.value.getIdToken();
        sessionStorage.setItem('token', token);
      }
    );
  }

  logout() {
    sessionStorage.clear();
    this.user.next(null);
    return signOut(this.auth);
  }

}
