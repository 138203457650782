<body class="m-0 row justify-content-center">
    <app-navquehacemos></app-navquehacemos>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- <div class="well well-sm"> -->
                    <br>
                     <div class="container "> 
                        <div class="row justify-content-center align-items-center text-center">
                            <div class="col">
                                <h6>Semillitas</h6>
                                <a routerLink="/semillas"><img src="/assets/fundacion.png" alt="Formacion" class="img-fluid" width="200" height="" ></a>
                            </div>
                            <div class="col">
                                <h6>Patrocinios</h6>
                                <a routerLink="/patrocinios"><img class="img-fluid" src="/assets/patrocinio6.jpg" alt="Patrocinio" width="225"></a>
                            </div>
                            <div class="col">
                                <h6>Proyección Social</h6>
                                <a routerLink="/semillas"><img src="/assets/pesebre.jpg" alt="Formacion" class="img-fluid" width="300" ></a>
                            </div>
                          </div>
                        <br>
                    </div>
                <!-- </div> -->
            </div>
        </div>
    </div>
</body>