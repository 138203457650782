import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactoComponent } from './component/contacto/contacto.component';
import { DonarComponent } from './component/donar/donar.component';
import { EquipoComponent } from './component/equipo/equipo.component';
import { FundacionComponent } from './component/fundacion/fundacion.component';
import { InicioComponent } from './component/inicio/inicio.component';
import { LoginComponent } from './component/login/login.component';
import { MisionComponent } from './component/mision/mision.component';
import { NoticiaComponent } from './component/noticias/noticia/noticia.component';
import { VistaComponent } from './component/noticias/vista/vista.component';
import { PatrocinioComponent } from './component/patrocinio/patrocinio.component';
import { ProyeccionSocialComponent } from './component/proyeccion-social/proyeccion-social.component';
import { QueHacemosComponent } from './component/que-hacemos/que-hacemos.component';
import { QuienesSomosComponent } from './component/quienes-somos/quienes-somos.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'proyeccion-social', component: ProyeccionSocialComponent },
  { path: 'quienes-somos', component: QuienesSomosComponent },
  { path: 'que-hacemos', component: QueHacemosComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'mision', component: MisionComponent },
  { path: 'equipo', component: EquipoComponent },
  { path: 'donar', component: DonarComponent },
  { path: 'semillas', component: FundacionComponent },
  { path: 'patrocinios', component: PatrocinioComponent },
  { path: 'noticias', component: NoticiaComponent },
  { path: 'noticias/noticia', component: VistaComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', pathMatch: 'full', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
