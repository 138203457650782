<body class="m-0 row justify-content-center">
    <app-navquehacemos></app-navquehacemos>

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="well well-sm">
                    <div class="col-auto p-5 text-center" id="body">
                        <h1>SEMILLAS</h1>
                        <div style="text-align: justify;">
                            Nuestro objetivo es brindar a través de la enseñanza de las TIC´S, el inglés y la práctica
                            deportiva,
                            herramientas que les permitan a nuestros beneficiarios las competencias básicas requeridas
                            hoy para
                            propender por un desarrollo social que permita mejorar su calidad de vida.
                        </div>
                    </div>

                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css"
                        rel="stylesheet"
                        integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6"
                        crossorigin="anonymous">


                    <!-- Gallery -->
                    <div class="row">

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/juegos3.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/alimentacion.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/foto4.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/ronda.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/charla.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/salacomputo.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/salacomputo1.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/voleibol.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/salacomputo3.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/futbol.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/charla.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />




                        </div>
                    </div>
                    <!-- Gallery -->

                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-JEW9xMcG8R+pH31jmWH6WWP0WintQrMb4s7ZOdauHnUtxwoG2vI5DkLtS3qm9Ekf"
                        crossorigin="anonymous"></script>

                </div>
            </div>
        </div>