<head>
  <script src="jquery-3.5.1.min.js"></script>
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
</head>

<body class="m-0 row justify-content-center">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div class="col-md-4">
        <a class="navbar-brand" href="#">
          <img
            src="/assets/fundacion.png"
            alt="Logo"
            width="80"
            height="80"
            class="d-inline-block align-text"
          />
          FUNDACIÓN CARLOS CUESTA
        </a>
      </div>
      <div class="row justify-content-around">
        <a
          class="fb redes col col-sm-3 align-self-center"
          href="https://www.facebook.com/Fundaci%C3%B3n-Carlos-Cuesta-Ubala-100373862362049/?ref=pages_you_manage"
          target="_blank"
          >FACEBOOK</a
        >
        <a
          class="nombre-instagram redes col col-sm-3 align-self-center"
          href="https://instagram.com/fcc_ubala?utm_medium=copy_link"
          target="_blank"
          >INSTAGRAM</a
        >
        <button
          type="button"
          class="btn btn-warning col col-sm-4 align-self-center"
          *ngIf="!token"
          [routerLink]="['/login']"
          routerLinkActive="router-link-active"
        >
          Iniciar sesión
        </button>
        <button
          type="button"
          class="btn btn-danger col col-sm-4 align-self-center"
          *ngIf="token"
          (click)="logout()"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  </nav>
</body>

<hr />
<nav class="navbar navbar-expand-lg bg-white">
  <div class="container-fluid justify-content-evenly">
    <div class="navbar-nav">
      <a
        [routerLink]="['/']"
        routerLinkActive="router-link-active"
        class="nav-item nav-link active"
        ><b>INICIO</b></a
      >
      <a
        [routerLink]="['/quienes-somos']"
        routerLinkActive="router-link-active"
        class="nav-item nav-link active"
        ><b>QUIÉNES SOMOS</b></a
      >
      <div class="nav-item dropdown">
        <a
          href="#"
          routerLinkActive="router-link-active"
          class="nav-link active dropdown-toggle"
          data-bs-toggle="dropdown"
          ><b> QUÉ HACEMOS</b></a
        >
        <div class="dropdown-menu bg-white">
          <a
            [routerLink]="['/semillas']"
            routerLinkActive="router-link-active"
            class="dropdown-item text-primary"
          >
            SEMILLAS</a
          >
          <a
            [routerLink]="['/patrocinios']"
            routerLinkActive="router-link-active"
            class="dropdown-item text-primary"
            >PATROCINIOS</a
          >
          <a
            [routerLink]="['/proyeccion-social']"
            routerLinkActive="router-link-active"
            class="dropdown-item text-primary"
            >PROYECCIÓN SOCIAL</a
          >
        </div>
      </div>
      <a
        [routerLink]="['/contacto']"
        routerLinkActive="router-link-active"
        class="nav-item nav-link active"
        ><b> CONTACTO</b></a
      >
      <a
        [routerLink]="['/donar']"
        routerLinkActive="router-link-active"
        class="nav-item nav-link active"
        ><b> DONAR</b></a
      >
      <a
        [routerLink]="['/noticias']"
        routerLinkActive="router-link-active"
        class="nav-item nav-link active"
        disabled="disabled"
        ><b> NOTICIAS</b></a
      >
    </div>
  </div>
</nav>
