import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  ngOnInit(): void {
    
  }
  constructor(public router: Router,) { }

  form = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required,Validators.email]),
    mensaje: new FormControl('', Validators.required),
  });

  public sendEmail(e: Event) {
    if(this.form.valid){
      e.preventDefault();
      emailjs.sendForm('service_fogenj6', 'template_9xch2si', e.target as HTMLFormElement, 'user_ngtDB480kCIDMmuKrCGra')
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
          Swal.fire({
            title: 'Enviado!',
            text: '¿Quieres continuar?',
            icon: 'success',
            confirmButtonText: 'Cool',
          })
          this.router.navigate(['/']);
        }, (error) => {
          console.log(error.text);
          Swal.fire({
            title: 'Error!',
            text: 'intenta de nuevo',
            icon: 'error',
            confirmButtonText: 'Cool'
          })
        });
    }else{
      alert("LLENE TODOS LOS CAMPOS DE FORMA CORRECTA.");
      console.log(this.form.value);
    }
  }
}



