<body class="m-0 row justify-content-center">
    <app-navquehacemos></app-navquehacemos>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="well well-sm">
                    <div class="col-auto p-5 text-center" id="body">
                        <h1>PATROCINIOS</h1>
                        <div style="text-align: justify;">
                            La Fundación Carlos Cuesta además de apoyar a los niños con su programa semillas, también apoya a jóvenes y adultos
                            desde el deporte, aportando con los implementos y premios en torneos de las diferentes
                            disciplinas deportivas que se practica en el departamento del Chocó.
                        </div>
                    </div>

                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css"
                        rel="stylesheet"
                        integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6"
                        crossorigin="anonymous">

                    <!-- Gallery -->
                    <div class="row">
                        <img class="img-fluid mb-3" src="/assets/patrocinio.jpeg" alt="Patrocinio" width="550"
                            height="200">
                        <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                            <img src="/assets/patrocinio1.jpeg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">

                            <img src="/assets/patrocinio5.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">
                        </div>

                        <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                            <img src="/assets/patrocinio2.jpeg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">

                            <img src="/assets/patrocinio3.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">
                        </div>

                        <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                            <img src="/assets/patrocinio4.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">

                            <img src="/assets/patrocinio6.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">

                        </div>

                        <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                            <img src="/assets/patrocinio8.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">


                        </div>

                        <div class="col-lg-4 col-md-12 mb-3 mb-lg-0">
                            <img src="/assets/patrocinio7.jpg" class="w-100 shadow-1-strong rounded mb-4"
                                alt="Patrocinio">


                        </div>

                    </div>
                    <!-- Gallery -->

                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-JEW9xMcG8R+pH31jmWH6WWP0WintQrMb4s7ZOdauHnUtxwoG2vI5DkLtS3qm9Ekf"
                        crossorigin="anonymous"></script>
                </div>
            </div>
        </div>
    </div>
</body>