import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  token: Subscription | undefined;

  constructor(private loginService: LoginService) {}

  ngOnInit(): void {
    this.loginService.currentUser.subscribe((currentUser) => {
      this.token = currentUser;
    });
  }

  logout(): void {
    this.loginService.logout();
    alert('sesion cerrada');
  }

  ngOnDestroy(): void {
    this.token?.unsubscribe();
  }
}
