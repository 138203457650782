<body class="m-0 row justify-content-center">
    <app-navquehacemos></app-navquehacemos>

    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="well well-sm">
                    <div class="col-auto p-5 text-center" id="body">
                        <h1>PROYECCIÓN SOCIAL</h1>
                    </div>

                    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css"
                        rel="stylesheet"
                        integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6"
                        crossorigin="anonymous">

                    <div class="col-md">
                        <iframe  src="https://drive.google.com/file/d/17MmCZNE_By5KNCOLFpmUKjFPxXeKtm1h/preview" width="640" height="480" allow="autoplay"></iframe>

                    </div>
                    <!-- Gallery -->

                    <div class="row mt-5">
                        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
                            <img src="/assets/Dia_del_niño.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/pesebre2.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/cancha.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/pesebre.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 mb-4 mb-lg-0">
                            <img src="/assets/entregaRegalo1.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                            <img src="/assets/pesebre1.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />

                        </div>

                        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">


                            <img src="/assets/pesebre5.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>

                        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">


                            <img src="/assets/ronda.jpg" class="w-100 shadow-1-strong rounded mb-4" alt="" />
                        </div>
                    </div>
                    <!-- Gallery -->

                    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/js/bootstrap.bundle.min.js"
                        integrity="sha384-JEW9xMcG8R+pH31jmWH6WWP0WintQrMb4s7ZOdauHnUtxwoG2vI5DkLtS3qm9Ekf"
                        crossorigin="anonymous"></script>

                </div>
            </div>
        </div>
    </div>
</body>