import { Component, OnInit } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { LoginService } from 'src/app/services/login/login.service';
import { noticias } from 'src/app/services/noticia/notice.model';
import { NoticiaService } from 'src/app/services/noticia/noticia.service';
import { finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.scss'],
})
export class NoticiaComponent implements OnInit {
  token: Subscription | undefined;
  noticeItem = new BehaviorSubject<any[]>([]);
  urlImage!: any;
  image: any;
  uploadPercent!: any;
  constructor(
    private login: LoginService,
    private noticiaService: NoticiaService,
    private storage: AngularFireStorage
  ) {
    this.noticiaService;
  }

  noti: any = {
    titulo: '',
    descripcion: '',
  };

  noticias?: noticias[] = [
    {
      titulo: 'CANCHA',
      descripcion:
        'ggdfsfdsgfdsgsdgdsggdsgsdgsddgsvgdgvdcucobibdsyidgvhdvgydsvyegdbcgbcbcfecvhsdvcyFDSJbhbewyuehfbdhcvDBGDBCYFEBFHKEBFDHIBIeebduivgyiewhffbvbdvbeyEBVDYEJEDBCBdhbchceyebcÑDHVGWEYWEBD',
      image: '',
    },
  ];

  ngOnInit(): void {
    this.login.currentUser.subscribe((currentUser) => {
      this.token = currentUser;
    });
    this.noticiaService.getAllNotice().subscribe((res) => {
      const datos: any[] = [];
      if (res.length > 0) {
        res.map((element: any) => {
          const data = {
            urlImage: element.payload.doc.data().urlImage,
            title: element.payload.doc.data().title,
            detail: element.payload.doc.data().detail,
            uid: element.payload.doc.id,
          };
          datos.push(data);
        });
        this.noticeItem.next(datos);
      }
    });
  }

  file(imagen: any) {
    this.image = imagen.target.files[0];
  }

  sendFile(file = this.image, datos = this.noti): void {
    const id = Math.random().toString(36).substring(2);
    const filePath = `notices/${id}`;
    const FirebaseNotice = this.noticiaService;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          return (this.urlImage = ref.getDownloadURL().subscribe({
            next(x) {
              const data = {
                urlImage: x,
                title: datos.titulo,
                detail: datos.descripcion,
              };
              return FirebaseNotice.createNotice(data)
                .then(() => {
                  return true;
                })
                .catch(() => {
                  return false;
                });
            },
          }));
        })
      )
      .subscribe();
    this.noti = {
      titulo: '',
      descripcion: '',
    };
  }

  // ngOnDestroy(): void {
  //   this.token?.unsubscribe();
  // }
}
