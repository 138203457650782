import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navquehacemos',
  templateUrl: './navquehacemos.component.html',
  styleUrls: ['./navquehacemos.component.scss']
})
export class NavquehacemosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
